.modal_inside {
  @apply absolute bg-white border border-solid border-black-10 rounded-lg;
}

.modal_overlay {
  @apply fixed flex items-center justify-center top-0 left-0 right-0 bottom-0 z-[500];
  background: rgba(105, 105, 105, 0.5);
}

.modal_overlay_danger {
  @apply fixed flex items-center justify-center top-0 left-0 right-0 bottom-0 z-[500];
  background: rgba(0, 0, 0, 0.8);
}

.modal_without_overlay {
  @apply fixed flex items-center justify-center top-0 left-0 right-0 bottom-0 z-[500];
}
