@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nippo';
  src: url('../public/fonts/Nippo-Extralight.otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nippo';
  src: url('../public/fonts/Nippo-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nippo';
  src: url('../public/fonts/Nippo-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nippo';
  src: url('../public/fonts/Nippo-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Nippo';
  src: url('../public/fonts/Nippo-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Nippo, Helvetica, sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-size: 100%;
  font-family: Nippo, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.marker_block {
  width: 27px;
  height: 36px;
  margin: -36px 0 0 -14px;
  position: absolute;
  z-index: 20;
}

.marker {
  transform: perspective(25px) rotateX(20deg) rotateZ(-45deg);
  transform-origin: 50% 50%;
  border-radius: 50% 50% 50% 0;
  outline: 2px solid #ffffff;
  padding: 0 3px 3px 0;
  width: 25px;
  height: 25px;
  position: relative;
  margin: 0;
  -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
}

.marker_image {
  transform: perspective(14px) rotateZ(45deg);
  margin-left: 4px;
  margin-top: 4px;
  width: 14px;
  height: 14px;
}

.marker_count {
  width: 14px;
  height: 14px;
  border-radius: 6px;
  border: 1px solid #ffffff;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  position: absolute;
  z-index: 30;
  top: 0;
  right: -3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

textarea {
  resize: none;
}

.select_country {
  opacity: 0.4;
}

.disabled_country {
  pointer-events: none !important;
  fill: rgba(216, 101, 64, 0.4) !important;
}
